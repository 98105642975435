import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ReduxState} from "../Store";


export interface AuthState {
    isLoggedIn: boolean,
    loginError?: string
}


const INITIAL_STATE: AuthState = {
    isLoggedIn: false
}

const authSlice = createSlice({
    name: 'auth',
    initialState: INITIAL_STATE,
    reducers: {
        setLoggedIn: (state, action: PayloadAction<boolean>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.isLoggedIn = true
        },
        setLoginError: (state, action: PayloadAction<string>) => {
            state.loginError = action.payload
        },
        logout: (state, action: PayloadAction<{ redirect?: string }>) => {
            state.isLoggedIn = false
        }
    },
})

export const {setLoggedIn, logout, setLoginError} = authSlice.actions
export const AuthSliceReducer = authSlice.reducer

const selectAuthSlice = (state: ReduxState) => state.auth

export const selectIsLoggedIn = createSelector(selectAuthSlice, state =>
    state.isLoggedIn
)

export const selectLoginError = createSelector(selectAuthSlice, state =>
    state.loginError
)


