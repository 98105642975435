import React from "react";

export const SpecScreen = () => {

    return <div className="w-12 h-full flex justify-content-center align-items-center bg-blue-50 flex-column">
        <h1>
            Spezifikation für den TKT QA Test
        </h1>
        <table>
            <thead>
            <tr>
                <th>Code</th>
                <th>Story</th>
                <th>Kategorie</th>
                <th>Unterkategorie</th>
            </tr>
            </thead>
            <tr>
                <td>A1</td>
                <td>Als ein Nutzer kann ich mich auf einer Login-Maske einloggen</td>
                <td>Login</td>
                <td>-</td>
            </tr>
            <tr>
                <td>A2</td>
                <td>Als ein Nutzer logge ich mich mit Namen und Passwort ein.</td>
                <td>Login</td>
                <td>-</td>
            </tr>
            <tr>
                <td>A3</td>
                <td>Als ein Nutzer muss mein Passwort mindestens 8 Zeichen lang sein.</td>
                <td>Login</td>
                <td>-</td>
            </tr>
            <tr>
                <td>A4</td>
                <td>Als ein Nutzer sehe ich nach dem Login die Event-Anmeldungsseite.</td>
                <td>Event</td>
                <td>-</td>
            </tr>
            <tr>
                <td>A5</td>
                <td>Als ein Nutzer kann ich mich auf der Seite in einem Formular zu einem Event anmelden.</td>
                <td>Event</td>
                <td>-</td>
            </tr>
            <tr>
                <td>A6</td>
                <td>Als ein Nutzer muss ich die Anzahl Gäste spezifizieren.</td>
                <td>Event</td>
                <td>Formular</td>
            </tr>
            <tr>
                <td>A7</td>
                <td>Als ein Nutzer kann ich einen Parkplatz mitbestellen.</td>
                <td>Event</td>
                <td>Formular</td>
            </tr>
            <tr>
                <td>A8</td>
                <td>Als ein Nutzer kann ich Sonderwünsche beim Essen mitteilen.</td>
                <td>Event</td>
                <td>Formular</td>
            </tr>
            <tr>
                <td>A9</td>
                <td>Als ein Nutzer kann ich eine Präferenz für einen Sitzplatz (Loge oder Parkett) angeben</td>
                <td>Event</td>
                <td>Formular</td>
            </tr>
            <tr>
                <td>A10</td>
                <td>Als ein Nutzer kann ich meine Zustimmung für Marketing-Nachrichten erteilen.</td>
                <td>Event</td>
                <td>Formular</td>
            </tr>
            <tr>
                <td>A11</td>
                <td>Als ein Nutzer muss ich den Datenschutzbestimmungen zustimmen.</td>
                <td>Event</td>
                <td>Formular</td>
            </tr>
            <tr>
                <td>A12</td>
                <td>Als ein Nutzer sehe ich nach der Anmeldung eine Erfolgs-Nachricht.</td>
                <td>Event</td>
                <td>Formular</td>
            </tr>
        </table>
    </div>
}
