import React from 'react';
import {Provider} from "react-redux";
import {ReduxStore} from "./Store";
import {TestScreen} from "./screens/TestScreen";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {SpecScreen} from "./screens/SpecScreen";


function App() {

    return (<Provider store={ReduxStore}>
            <BrowserRouter>
                <Routes>
                    <Route key={"main"} path={"/"} element={<TestScreen/>}/>
                    <Route key={"spec"} path={"/spec"} element={<SpecScreen/>}/>
                    <Route key={"verboten"} path={"*"} element={<div>404</div>}/>
                </Routes>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
