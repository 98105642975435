import React from "react";
import {useSelector} from "react-redux";
import {selectIsLoggedIn} from "../slices/AuthSlice";
import {LoginPart} from "./parts/LoginPart";
import {EventRegistrationPart} from "./parts/EventRegistrationPart";


export const TestScreen = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn)


    return <div className="w-12 h-full flex justify-content-center align-items-center bg-blue-50 flex-column">
        {isLoggedIn ? <EventRegistrationPart/> : <LoginPart/>}
    </div>
}
