import {Message} from "primereact/message";
import {Card} from "primereact/card";
import {ValidatedField} from "../../components/ValidatedField";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectLoginError, setLoggedIn, setLoginError} from "../../slices/AuthSlice";
import {useFormik} from "formik";
import * as Yup from "yup";

interface FormState {
    username: string
    password: string
}


export const LoginPart = () => {
    const loginError = useSelector(selectLoginError)
    const dispatch = useDispatch()

    const formik = useFormik<FormState>({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            username: Yup
                .string()
                .required("E-Mail is a required field."),
            password: Yup
                .string()
                .min(8, "Ein Passwort braucht mindestens 8 Zeichen.")
                .required("Passwort wird benötigt."),
        }),
        onSubmit: (data) => {
            if (data.username === "Heinz Unterberger") {
                dispatch(setLoggedIn(true))
            } else {
                dispatch(setLoginError("Das Einloggen ist leider fehlgeschlagen."))
            }
        }
    });

    return <div className="w-12 md:w-8 lg:w-6 xl:w-4 p-5 ">
        <Message
            className="p-3 mb-5"
            severity="success" content={<div>
            <h2>Welcome to the the key - technology QA Test</h2>
            <p>The goal of this test is to find all the hidden issues. This means both issues in implementing the spec
                and bugs or things that just don't make sense. Please read the <a
                    href="/spec" target={"_blank"}>spec</a> carefully and then validate that this application fulfills
                all
                the requirements. The application and documentation is in <strong>German</strong>.</p>
            <p>There are several errors hidden in this application. Try to find them all and document them in a way that
                would help a developer fix them. All of the errors are clearly identifiable as such.</p>
            <p>Use this account to test: <br/>
                Name: Heinz Unterberger <br/>
                Password: bestcompanyever
            </p>
        </div>}>
        </Message>

        <Card
            className="p-3"
            header={<h1 className="m-0">Einloggen</h1>}
        >

            <form onSubmit={formik.handleSubmit} className="p-fluid mb-5">
                <ValidatedField<FormState, string>
                    className="mb-4"
                    name={"username"}
                    label={"E-Mail"}
                    required={true}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedField<FormState, string>
                    className="mb-6"
                    name={"password"}
                    label={"Passwort"}
                    required={true}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <Password id={fieldName} name={fieldName} value={fieldValue}
                                         onChange={e => updateField(e.target.value)}
                                         toggleMask
                                         feedback={false}
                                         className={classNames({'p-invalid': !isValid})}
                        />

                    }}/>
                <Button disabled={formik.isSubmitting} type="submit" label="Einloggen" className="p-mt-2"/>
            </form>

            {loginError && <Message severity="error" content={loginError}/>}
        </Card>
    </div>
}
