import {Card} from "primereact/card";
import {ValidatedField} from "../../components/ValidatedField";
import {classNames} from "primereact/utils";
import {Button} from "primereact/button";
import React, {useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {InputNumber} from "primereact/inputnumber";
import {InputSwitch} from "primereact/inputswitch";
import {InputTextarea} from "primereact/inputtextarea";
import styled from "styled-components";

interface FormState {
    numberOfGuests: number
    parkingRequired: boolean
    foodRequirements: string
    advertisingOk: boolean
    legal: boolean
}


export const EventRegistrationPart = () => {

    const [isSubmitted, setSubmitted] = useState(false)

    const formik = useFormik<FormState>({
        initialValues: {
            numberOfGuests: 1,
            parkingRequired: false,
            foodRequirements: "",
            advertisingOk: false,
            legal: false,
        },
        validationSchema: Yup.object().shape({
            numberOfGuests: Yup
                .number()
                .required("Anzahl Gäste ist ein notwendiges Feld."),
        }),
        onSubmit: (data, {setSubmitting}) => {
            setSubmitted(true)
            setSubmitting(false)
        }
    });
    return <ProblemBox className="w-12 md:w-8 lg:w-6 p-5">
        <h1>Willkommen Herr Untermeier!</h1>
        <p className="mb-5">Nutzen Sie das unternstehende Formular um sich für das nächste Event anzumelden.</p>

        {isSubmitted ? <Card>
                Vielen Dank für Ihre Anmeldung!
            </Card> :

            <Card
                id="test"
                className="p-3"
                header={<h1 className="m-0">Einloggen</h1>}
            >

                <form onSubmit={formik.handleSubmit} className="p-fluid mb-5">
                    <ValidatedField<FormState, number>
                        className="mb-4"
                        name={"numberOfGuests"}
                        label={"Anzahl Gäste"}
                        required={true}
                        formikConfig={formik}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return <InputNumber step={1} id={fieldName} name={fieldName} value={fieldValue}
                                                onChange={e => updateField(e.value || undefined)}
                                                className={classNames({'p-invalid': !isValid})}/>

                        }}/>

                    <ValidatedField<FormState, string>
                        className="mb-4"
                        name={"foodRequirements"}
                        label={"Speziele Wünsche beim Essen"}
                        required={true}
                        formikConfig={formik}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return <InputTextarea id={fieldName} name={fieldName} value={fieldValue}
                                                  onChange={e => updateField(e.target.value)}
                                                  className={classNames({'p-invalid': !isValid})}/>

                        }}/>
                    <ValidatedField<FormState, boolean>
                        className="mb-4"
                        name={"parkingRequired"}
                        label={"Parkplatz benötigt?"}
                        required={true}
                        formikConfig={formik}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return <div><InputSwitch id={fieldName} name={fieldName}
                                                     checked={fieldValue}
                                                     onChange={e => updateField(e.target.value)}
                                                     className={classNames({'p-invalid': !isValid})}
                            /></div>

                        }}/>

                    <ValidatedField<FormState, boolean>
                        className="mb-4"
                        name={"advertisingOk"}
                        label={"Marketing Opt-In"}
                        required={true}
                        formikConfig={formik}
                        helpText={"Hiermit erlauben Sie uns, Sie zu Marktingzwecken zu kontaktieren."}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return <div><InputSwitch id={fieldName} name={fieldName}
                                                     checked={fieldValue}
                                                     onChange={e => updateField(e.target.value)}
                                                     className={classNames({'p-invalid': !isValid})}
                            /></div>

                        }}/>
                    <ValidatedField<FormState, boolean>
                        className="mb-4"
                        name={"legal"}
                        label={"Rechtliche Zustimmung"}
                        required={true}
                        formikConfig={formik}
                        helpText={<div>Ich habe die <a
                            href="/datenschutzbestimmungen/" target="_blank">Datenschutzbestimmungen</a> gelesen und stimme diesen zu.
                        </div>}
                        component={({fieldValue, updateField, fieldName, isValid}) => {
                            return <div><InputSwitch id={fieldName} name={fieldName}
                                                     checked={fieldValue}
                                                     onChange={e => updateField(e.target.value)}
                                                     className={classNames({'p-invalid': !isValid})}
                            /></div>

                        }}/>
                    <Button disabled={formik.isSubmitting} type="submit" label="Anmelden!" className="p-mt-2"/>
                </form>
            </Card>}

        <div className="text-center mt-5 mb-5 text-sm text-300">
            <a href="#test">Abmelden</a>
        </div>
    </ProblemBox>
}

const ProblemBox = styled.div`
    @media screen and (min-width: 1260px) and (max-width: 1440px) {
      width: 150% !important;
    }
   `
