import {FormikState} from "formik";
import React, {ReactNode} from "react";

// @ts-ignore
import {classNames} from "primereact/utils";
import styled from "styled-components";

export interface FieldConfig<FieldType> {
    fieldValue: FieldType | undefined,
    updateField: (newValue: FieldType | undefined) => void,
    fieldName?: string,
    required?: boolean;
    isValid?: boolean,
    disabled?: boolean
    placeholder?: string
}

interface ValidatedFieldPropsV2<State, FieldType> {
    label?: string;
    name: keyof State & string;
    required?: boolean;
    disabled?: boolean
    placeholder?: string

    formikConfig: FormikState<State> & {
        setFieldTouched: (field: string, touched?: boolean, shouldValidate?: boolean | undefined) => any;
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
    }

    iconClass?: string;
    className?: string;
    helpText?: ReactNode;

    component: (renderConfig: FieldConfig<FieldType>) => ReactNode
}

export function ValidatedField<State, FieldType>({
                                                     className,
                                                     iconClass,
                                                     name,
                                                     label,
                                                     formikConfig,
                                                     helpText,
                                                     component,
                                                     required,
                                                     disabled,
                                                     placeholder
                                                 }: ValidatedFieldPropsV2<State, FieldType>) {
    const hasError = formikConfig.errors[name] && formikConfig.touched[name];

    const value = formikConfig.values[name] as unknown as FieldType

    const updateValue = (updatedValue: FieldType | undefined) => {
        formikConfig.setFieldTouched(name, true);
        formikConfig.setFieldValue(name, updatedValue);
    }

    const FieldContent = <>
        {iconClass && <i className={`pi ${iconClass}`}/>}

        {component({
            fieldValue: value,
            isValid: !hasError,
            fieldName: name,
            updateField: updateValue,
            required: required || false,
            disabled: disabled || false,
            placeholder: placeholder
        })}
    </>

    return (
        <div className={`field ${className ? className : ""}`}>
            {label ?
                <label htmlFor={name}
                         className={classNames("ui-widget", {'p-error': hasError})}>{label} {required ?
                    <Required>*</Required> : ""}</label> : null}

            {iconClass ? <span className="p-input-icon-right">{FieldContent}</span> : FieldContent}

            {hasError ? (
                <small className="p-error">{(formikConfig.errors as any)[name]}</small>
            ) : null}

            {helpText ? (
                <small className="mt-2">{helpText}</small>
            ) : null}
        </div>);
}

const Required = styled.span`
  color: var(--danger)
`
